import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "../node_modules/bulma/css/bulma.css";
import { createHead } from "@vueuse/head";
import VueGtag from "vue-gtag";

const head = createHead();

createApp(App)
  .use(head)
  .use(router)
  .use(VueGtag, {
    config: { id: "G-Z2RYHSZKNM" },
  })
  .mount("#app");
