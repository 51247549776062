<template>
  <div class="home">
    <div class="container">
      <div v-if="data.condition_text" class="columns">
        <div class="column">
          <div class="box has-text-centered">
            <h1 class="title">Edirne</h1>

            <p class="heading">{{ data.condition_text }}</p>
            <img
              :src="`${data.condition_icon}`"
              :alt="`${data.condition_text}`"
            />
          </div>
        </div>
        <div class="column is-three-fifths has-text-centered">
          <div class="level has-text-centered">
            <div class="level-item">
              <div class="box">
                <p class="heading">Hissedilen</p>
                <p class="title">{{ data.feelslike_c }}&deg</p>
              </div>
            </div>
            <div class="level-item">
              <div class="box">
                <p class="heading">Sıcaklık</p>
                <p class="title">{{ data.temp_c }}&deg</p>
              </div>
            </div>
            <div class="level-item">
              <div class="box">
                <p class="heading">Nem</p>
                <p class="title">%{{ data.humidity }}</p>
              </div>
            </div>
            <div class="level-item">
              <div class="box">
                <p class="heading">Rüzgar</p>
                <p class="title">{{ data.wind_kph }} Kph</p>
              </div>
            </div>
          </div>
          <span>Son güncelleme: {{ data.last_updated }}</span>
          <div class="level has-text-centered mt-3">
            <div class="level-item">
              <div class="box">
                <p class="heading">{{ data.forecast[0].date }}</p>
                <p class="subtitle is-size-6">
                  {{ data.forecast[0].day.condition.text }}
                </p>
                <img
                  :src="`${data.forecast[0].day.condition.icon}`"
                  :alt="`${data.forecast[0].day.condition.text}`"
                />
                <p class="subtitle">
                  {{ data.forecast[0].day.avgtemp_c }} &deg
                </p>
              </div>
            </div>
            <div class="level-item">
              <div class="box">
                <p class="heading">{{ data.forecast[1].date }}</p>
                <p class="subtitle is-size-6">
                  {{ data.forecast[1].day.condition.text }}
                </p>
                <img
                  :src="`${data.forecast[1].day.condition.icon}`"
                  :alt="`${data.forecast[1].day.condition.text}`"
                />
                <p class="subtitle">
                  {{ data.forecast[1].day.avgtemp_c }} &deg
                </p>
              </div>
            </div>
            <div class="level-item">
              <div class="box">
                <p class="heading">{{ data.forecast[2].date }}</p>
                <p class="subtitle is-size-6">
                  {{ data.forecast[2].day.condition.text }}
                </p>
                <img
                  :src="`${data.forecast[2].day.condition.icon}`"
                  :alt="`${data.forecast[2].day.condition.text}`"
                />
                <p class="subtitle">
                  {{ data.forecast[2].day.avgtemp_c }} &deg
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>{{ errMsg.message }}</div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
export default {
  name: "HomeView",

  setup() {
    let data = reactive({
      feelslike_c: null,
      temp_c: null,
      humidity: null,
      condition_text: "",
      condition_icon: "",
      last_updated: "",
      wind_kph: null,
      forecast: [],
    });

    let errMsg = reactive({
      message: "",
    });

    const fetchWeather = async () => {
      try {
        const res = await fetch(
          "https://api.weatherapi.com/v1/forecast.json?key=b2db1cfbbd4c46a2b0694329231112&q=Edirne&days=3&aqi=no&alerts=no&lang=tr"
        );
        const result = await res.json();
        if (result.error) {
          errMsg.message = result.error.message;
        } else {
          data.feelslike_c = result.current.feelslike_c;
          data.temp_c = result.current.temp_c;
          data.humidity = result.current.humidity;
          data.condition_text = result.current.condition.text;
          data.condition_icon = result.current.condition.icon;
          data.last_updated = result.current.last_updated;
          data.wind_kph = result.current.wind_kph;
          data.forecast = result.forecast.forecastday;
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchWeather();
    return { data, fetchWeather, errMsg };
  },
};
</script>

<style>
.home {
  margin-top: 30px;
  min-height: 50vh;
}
</style>
