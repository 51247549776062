import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/kullanim-sozlesmesi",
    name: "KullanimSozlesmesi",

    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/KullanimSozlesmesiView.vue"
      ),
  },
  {
    path: "/gizlilik-politikasi",
    name: "GizlilikPolitikasi",

    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/GizlilikPolitikasiView.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
