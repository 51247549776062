<template>
  <nav
    class="navbar has-shadow is-spaced"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand is-align-items-center">
      <router-link to="/">
        <img src="./assets/logo.png" alt="edirnehavadurumu.com.tr" />
      </router-link>
      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbar-main-menu"
        @click="toggle"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div
      id="navbar-main-menu"
      class="navbar-menu"
      :class="{ 'is-active': isMobile }"
    >
      <div class="navbar-end">
        <router-link to="/" class="navbar-item">Anasayfa</router-link>
        <router-link to="/kullanim-sozlesmesi" class="navbar-item"
          >Kulanım Sözleşmesi</router-link
        >
        <router-link to="/gizlilik-politikasi" class="navbar-item"
          >Gizlilik Politikası</router-link
        >
      </div>
    </div>
  </nav>
  <router-view />
  <footer class="footer">
    <div class="content is-flex is-justify-content-space-between">
      <span class="has-text-left is-size-7"
        >© 2023 edirnehavadurumu.com.tr</span
      >
      <span class="has-text-right is-size-7">info@edirnehavadurumu.com.tr</span>
    </div>
  </footer>
</template>

<script>
import { ref, reactive } from "vue";
import { useHead } from "@vueuse/head";

export default {
  setup() {
    const siteData = reactive({
      title: "Edirne Hava Durumu | edirnehavadurumu.com.tr",
      description:
        "Edirne Hava Durumu. Edirne için güncel hava durumunu öğrenin. ",
    });

    useHead({
      title: siteData.title,
      meta: [{ name: "description", content: siteData.description }],
    });

    let isMobile = ref(false);
    function toggle() {
      isMobile.value = !isMobile.value;
    }
    return { isMobile, toggle };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active,
a.navbar-item:hover {
  color: #42b983 !important;
}
</style>
